


























































































import { Component, Vue } from 'vue-property-decorator'
import { apiRechargeData } from '@/api/application/recharge'

@Component
export default class RechargeSurvey extends Vue {
    /** S Data **/
    statisticsData = {} // 统计数据
    topRule = [] // 充值规则排行榜列表
    topUser = [] // 充值排行榜列表
    /** E Data **/

    /** S Life Cycle **/
    created() {
        apiRechargeData()
            .then(res => {
                this.statisticsData = res.recharge_data
                this.topUser = res.top_user
                this.topRule = res.top_rule
            })
            .catch(() => {
                this.$message.error('请求数据失败，请刷新重载!')
            })
    }
    /** S Life Cycle **/
}
